
























import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import CurriculumSettingTable, { CurriculumSettingsMaster } from '@/components/organisms/CurriculumSettingTable.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import LoadStudentsApi from '@/mixins/teacher/LoadStudentsApi'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    TableBase,
    CurriculumSettingTable,
  },
})
export default class Student extends Mixins(QueryMethods, LoadClassOptionsApi, LoadStudentsApi) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private userIds: number[] = []
  private searchQuery = ''
  private loaded = false
  private isProcessing = false
  private submitParams: any = null

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  @Ref() curriculumSettingTable?: CurriculumSettingTable

  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    {
      text: '生徒',
      href: this.isV3 ? '/teacher/setting/v3/student' : '/teacher/setting/student',
    },
    { text: '時間割一括登録', active: true },
  ]

  private curriculumSettingsMaster: CurriculumSettingsMaster = {
    classPrivateOptionDatas: [],
    classGroupOptionDatas: [],
  }

  get submitButtonColor(): string {
    return this.submitParams ? 'white' : 'pointer-events-none'
  }

  public setSubmitParams() {
    if (this.curriculumSettingTable) {
      this.submitParams = this.curriculumSettingTable.createApiParams()
    }
  }

  private tableBaseItems: {
    name: string[]
    school: string
    grade: string
    attribute: string
    gid: string
  }[] = []

  private tableBaseFields = [
    { key: 'name', label: '氏名・ID' },
    { key: 'school', label: '学校' },
    { key: 'grade', label: '学年' },
    { key: 'attribute', label: 'グループ' },
    { key: 'gid', label: 'G-ID' },
  ]

  private tableMultipleLines = ['name']

  private async submitButton() {
    if (!confirm('対象生徒の時間割が全て上書きされます。よろしいですか？')) return
    await this.submit()
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadInitialize()
    this.loaded = true
    Vue.prototype.$loading.complete()
  }

  // 初回情報読み込み
  private async loadInitialize() {
    this.setParamsFromQuery()
    // ID指定がない場合は戻る
    if (this.userIds.length == 0) {
      this.jumpToListpage()
      return
    }
    await this.loadClasses()
    await this.loadStudentDatas()
  }

  // クエリからパラメータ取得
  private async setParamsFromQuery() {
    const params = this.getParamsObject()
    // ID
    if (params['id']) {
      this.userIds = params['id'].split(',')
    }
    // 検索条件
    if (params['search']) {
      this.searchQuery = decodeURIComponent(params['search'])
    }
  }

  // 生徒情報読み込み・検索
  private async loadStudentDatas() {
    // 固定パラメータ設定
    const params = this.getSearchParamsBase(this.branchId)
    params['userId'] = this.userIds.join(',')
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudents(params)
    this.tableBaseItems = data.students.map((student) => {
      return {
        name: [`${student.nickname}`, `${student.studentCode}`],
        school: student.schoolName,
        grade: student.gradeName,
        attribute: student.userAttributeTitle ? student.userAttributeTitle : '－',
        gid: student.isGidRegistered ? '済' : '未',
      }
    })
  }

  // 授業情報取得
  private async loadClasses() {
    this.curriculumSettingsMaster.classPrivateOptionDatas = await this.getPrivateClassOptions(this.branchId)
    this.curriculumSettingsMaster.classGroupOptionDatas = await this.getGroupClassOptions(this.branchId)
  }

  private async submit() {
    if (this.isProcessing) return
    this.isProcessing = true
    Vue.prototype.$loading.start()
    const params = {
      studentIds: this.userIds,
      settings: this.submitParams,
    }
    Vue.prototype.$http.httpWithToken
      .post('/studentTimetables/bulkCreate/', params)
      .then(() => {
        alert('登録しました。')
        this.jumpToListpage()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('登録に失敗しました。授業設定が重複しています。')
        } else {
          alert('登録に失敗しました。')
        }
      })
      .finally(() => {
        this.isProcessing = false
        Vue.prototype.$loading.complete()
      })
  }

  // 一覧画面へ遷移
  private jumpToListpage() {
    const jumpto = this.isV3
      ? `/teacher/setting/v3/student/${this.searchQuery}`
      : `/teacher/setting/student/${this.searchQuery}`
    Vue.prototype.$loading.complete()
    window.location.href = jumpto
  }
}
