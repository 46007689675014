































import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

type SelectOption = {
  text: string
  value: string
}
export type CurriculumSettingsMaster = {
  classPrivateOptionDatas: SelectOption[]
  classGroupOptionDatas: SelectOption[]
}
class CurriculumSelectInfo {
  constructor() {
    this.key = new Date().getTime().toString(16)
    this.classValue = null
    this.type = null
  }
  key: string
  classValue: string | null
  type: number | null
}

@Component({
  components: {
    ButtonBase,
    SelectBase,
  },
})
export default class CurriculumSettingTable extends Vue {
  @Prop()
  curriculumSettingsMaster!: CurriculumSettingsMaster
  @Prop({ default: null })
  onChangeFunction?: any

  private curriculumTypeItems = [
    { text: '集団', value: 1 },
    { text: '個別', value: 2 },
  ]

  private curriculumSelectedList: CurriculumSelectInfo[] = []

  private typeChanged(currriculumSelected: CurriculumSelectInfo) {
    const curriculums = this.curriculumOptions(currriculumSelected.type)
    for (let i = 0; i < curriculums.length; i++) {
      if (curriculums[i].value == currriculumSelected.classValue) {
        // 現在のモードのカリキュラムに選択済みの値があればそのまま許容
        return
      }
    }
    // ない場合はnullを設定してリセット
    currriculumSelected.classValue = null
    this.onSelected()
  }

  @Watch('curriculumSelectedList')
  private onSelected() {
    if (this.onChangeFunction !== null) this.onChangeFunction()
  }

  get addButtonColor(): string {
    return this.curriculumSelectedList.length < 20 ? 'white' : 'pointer-events-none'
  }

  get curriculumOptions(): any {
    const _: any = this
    return function (selected: number | null): SelectOption[] {
      if (selected == 1) {
        return _.curriculumSettingsMaster.classGroupOptionDatas
      } else if (selected == 2) {
        return _.curriculumSettingsMaster.classPrivateOptionDatas
      }
      return []
    }
  }

  public validated(): boolean {
    return this.createApiParams() !== null
  }

  private mounted() {
    this.addSettingRow()
  }

  // 行追加
  private addSettingRow() {
    if (this.curriculumSelectedList.length < 20) {
      this.curriculumSelectedList.push(new CurriculumSelectInfo())
    }
  }

  // 行削除
  private removeSettingRow(index: number) {
    this.curriculumSelectedList.splice(index, 1)
  }

  // 登録API用パラメータ生成
  // エラー時null
  public createApiParams(): any {
    if (this.curriculumSelectedList.length === 0) {
      return null
    }
    const params = []
    for (let i = 0; i < this.curriculumSelectedList.length; i++) {
      const selectedCurriculum = this.curriculumSelectedList[i]
      if (!selectedCurriculum.classValue) {
        return null
      }
      const addClassInformations = selectedCurriculum.classValue.split(',')
      const isGroup = addClassInformations[0] == 'true'
      const classId = addClassInformations[1]
      let period = null
      if (addClassInformations.length > 2) {
        period = addClassInformations[2]
      }

      const param = {
        isGroup: isGroup,
        isEnabled: true,
        classSettingId: classId,
        period: period,
      }
      params.push(param)
    }

    return params
  }
}
